import React from 'react';
import { Button } from 'reactstrap';
import { Link } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';

const ViewContent = styled.div`
  background: #fff;
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  display: flex;
  flex-flow: column nowrap;
  box-shadow: 0 0 100px #1b1b2c;
`;

const ViewArticle = styled.article`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  max-width: 100%;
  text-align: center;
`;

const ViewButton = styled(Button)`
  margin-top: 2rem;
`;

const NotFound = () => (
  <Layout location={typeof window !== 'undefined' ? location : null}>
    <SEO title="Erreur 404" pathname="/404/" />
    <ViewContent>
      <ViewArticle>
        <h1>
          <strong>Oups !</strong>
          <br />
          Mauvaise adresse :(
        </h1>
        <Link to="/">
          <ViewButton color="success">
            <i className="fa fa-home" /> Retour Accueil
          </ViewButton>
        </Link>
      </ViewArticle>
    </ViewContent>
  </Layout>
);

export default NotFound;
